import * as React from 'react'
import { Link } from 'gatsby'
import Site from '../components/Layout/Site'
import Seo from '../components/Layout/Seo'
import { Heading, Paragraph } from '../components/UI/Typography'
import { Section } from '../components/UI/Section'
import DefaultVector from '../components/Shared/DefaultVector'
import Element from '../components/UI/Element'
import Button from '../components/UI/Button'
import { StaticImage } from 'gatsby-plugin-image'

// markup
const NotFoundPage = () => {
  return (
    <Site>
      <Seo title="Page Not Found" />
      <Section py={{ _: 16, lg: 0 }}>
        <DefaultVector>
          <Element textAlign="center">
            <StaticImage
              src="../images/coming-soon.png"
              alt="vector"
              layout="constrained"
              height={100}
            />
            <br />
            <br />
            <Heading as="h1" size="display-3">
              Page Not Found!
            </Heading>
            <Paragraph mb={10}>Sorry but the requested page is not found.</Paragraph>

            <Button as={Link} variant="brand" to="/">
              Go to homepage
            </Button>
          </Element>
        </DefaultVector>
      </Section>
    </Site>
  )
}

export default NotFoundPage
